import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { ModalGalleryComponent, ModalGalleryItem } from './components/ModalGallery';

export type ModalGalleryProps = {
    items: ModalGalleryItem[];
    galleryId: number | string;
};

export const ModalGallery = ({ items, galleryId }: ModalGalleryProps) => {
    const { galleryIdActive } = useGalleryModal();

    return galleryId === galleryIdActive && <ModalGalleryComponent items={items} />;
};
