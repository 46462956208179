import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { StyledCarousel } from '../Carousel';

export const StyledGalleryModal = styled.div({
    width: '100vw',
    height: '100vh',
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '.swiper': {
        height: '100%',
    },
    [`${StyledCarousel}`]: {
        height: '100%',
    },
});

export const StyledGalleryItem = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    maxWidth: '100%',
    maxHeight: '100%',
});

export const StyledGalleryItemInner = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1400,
    height: '70%',
    maxHeight: 830,
    margin: 20,
    [breakpoints.sm]: {
        margin: 50,
    },
});

export const StyledButtonWrapper = styled.div<{ align: 'left' | 'right' }>(({ align }) => ({
    position: 'absolute',
    top: 'calc(50% - 25px)',
    // Small spacing between buttons and image edge
    [align]: 25,
    zIndex: 300000,
    [breakpoints.sm]: {
        [align]: 55,
    },
}));

export const StyledImageWrapper = styled.div({
    display: 'flex',
    flexFlow: 'column wrap',
    width: '100%',
    maxWidth: 1350,
    height: '100%',
    maxHeight: 800,
});

export const StyledImageRatio = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    borderRadius: theme.borderRadius.md,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
    [breakpoints.sm]: {
        borderRadius: '45px 15px',
    },
    img: {
        borderRadius: theme.borderRadius.md,
        overflow: 'hidden',
        [breakpoints.sm]: {
            borderRadius: '45px 15px',
        },
    },
}));

export const StyledVideoWrapper = styled.div(() => ({
    position: 'absolute',
    width: '100%',
    maxWidth: 1350,
    height: '100%',
    maxHeight: 800,
}));

export const StyledVideoInnerWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    borderRadius: theme.borderRadius.md,
    overflow: 'hidden',
    [breakpoints.sm]: {
        borderRadius: '45px 15px',
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

export const StyledCaptionWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    textAlign: 'center',
    maxWidth: '80%',
    padding: theme.spaces[4],
}));
