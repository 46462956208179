import React, { useState, useEffect, useMemo } from 'react';

type UseSwiperRefProps = {
    ref?: React.RefObject<HTMLButtonElement>;
};

// Solution from https://github.com/nolimits4web/swiper/issues/3855#issuecomment-829278905
const useSwiperRef = ({ ref: initialRef }: UseSwiperRefProps = {}, dependencies = []) => {
    const [element, setElement] = useState<HTMLElement | null>(null);
    const ref = useMemo(() => {
        return initialRef || React.createRef<HTMLButtonElement>();
    }, []);

    useEffect(() => {
        setElement(ref.current);
    }, dependencies);

    return {
        element,
        ref,
    };
};

export default useSwiperRef;
