import React from 'react';
import useSwiperRef from './hooks/useSwiperRef';
import { StyledCarousel } from './styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, SwiperOptions, Navigation, Mousewheel } from 'swiper';
import 'swiper/css';

SwiperCore.use([A11y, Navigation, Mousewheel]);

export interface CarouselProps {
    /**
     * A list of items to be shown in a slide
     */
    items: React.ReactNode[];
    prevRef?: React.RefObject<HTMLButtonElement>;
    nextRef?: React.RefObject<HTMLButtonElement>;
    setSwiper?: React.Dispatch<React.SetStateAction<SwiperCore | undefined>>;
    onActiveIndexChange?: (index: number) => void;
    config?: SwiperOptions;
}

export const Carousel = ({
    items,
    prevRef: initialPrevRef,
    nextRef: initialNextRef,
    setSwiper,
    onActiveIndexChange,
    config: customConfig,
}: CarouselProps) => {
    const { element: prevEl } = useSwiperRef({ ref: initialPrevRef });
    const { element: nextEl } = useSwiperRef({ ref: initialNextRef });

    const config: SwiperOptions = {
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        spaceBetween: 30,
        watchOverflow: true,
        speed: 500,
        mousewheel: {
            forceToAxis: true, // Enables horizontal scroll, but prevents carousel from hijacking regular vertical scroll
        },
        freeMode: true,
        a11y: {
            slideRole: 'listitem',
        },
        navigation: {
            prevEl,
            nextEl,
        },
        ...customConfig,
    };

    return (
        <StyledCarousel>
            <Swiper
                role="list"
                {...config}
                onSwiper={setSwiper}
                onSlideChange={(e) => onActiveIndexChange && onActiveIndexChange(e.activeIndex)}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
            </Swiper>
        </StyledCarousel>
    );
};
